/* eslint-disable i18next/no-literal-string */

type ID = string | number;

export const getPaths = {
  index: () => "/",
  404: () => "/404",
  // ##################################
  // ##
  // ##     Atletas
  // ##
  atletas: {
    index: () => "/athletes",
    id: (id: ID) => `/athletes/${id}`,
  },
  // ##################################
  // ##
  // ##     Auth
  // ##
  auth: {
    login: () => "/auth/sign_in",
    loginAdmin: () => "/auth/sign_in_admin",
    recoverPassword: () => "/auth/recover_password",
    setPassword: () => "/auth/set_password",
  },
  // ##################################
  // ##
  // ##     Circuitos
  // ##
  circuitos: {
    type: (type: ID) => `/circuits/${type}`,
    detalhe: (id: ID) => `/circuits/detail/${id}`,
  },
  // ##################################
  // ##
  // ##     Parametros-treino
  // ##
  parametrosTreino: {
    altimetries: () => "/training_params/altimetries",
    durations: () => "/training_params/durations",
    modalities: () => "/training_params/modalities",
    terrains: () => "/training_params/terrains",
    zones: () => "/training_params/zones",
  },
  // ##################################
  // ##
  // ##     Perfis-atleta
  // ##
  perfisAtleta: {
    index: () => "/athlete_profiles",
    id: (id: ID) => `/athlete_profiles/${id}`,
  },
  // ##################################
  // ##
  // ##     Planos_subscricoes
  // ##
  planosSubscricoes: {
    planos: {
      id: (id: ID) => `/subscription_plans/plans/${id}`,
      create: () => "/subscription_plans/plans/create",
    },
    subscricoes: {
      index: () => "/subscription_plans/subscriptions",
    },
    vouchers: {
      index: () => "/subscription_plans/vouchers",
    },
  },
  // ##################################
  // ##
  // ##     Treinadores
  // ##
  treinadores: {
    index: () => "/coaches",
    id: (id: ID) => `/coaches/${id}`,
  },
  // ##################################
  // ##
  // ##     Treinos
  // ##
  treinos: {
    blocos: {
      index: () => "/training/blocks",
      id: (id: ID) => `/training/blocks/${id}`,
    }
  },
  // ##################################
  // ##
  // ##     Website
  // ##
  website: {
    testemunhos: {
      index: () => "/website/reviews",
      id: (id: ID) => `/website/reviews/${id}`,
    },
  },
};
