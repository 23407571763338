{/* eslint-disable i18next/no-literal-string */}
const ButtonStyles = {
  baseStyle: {
    borderRadius: 0,
    textTransform: "uppercase",
    color: "white",
    fontWeight: "bold",
    fontSize: "sm",
  },
};

export default ButtonStyles;
