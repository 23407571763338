import { CircularProgress } from "@chakra-ui/progress";
import { Center, Image } from "@chakra-ui/react";
import { LINKS } from "lib/utils/constants";
import React from "react";

export default function AdminLoadingPage() {
  return (
    <Center w={"100%"} h={"100vh"} flexGrow={1} flexDirection="column">
      <Image
        // eslint-disable-next-line i18next/no-literal-string
        alt="myHeart"
        src={LINKS.images.bigLogo}
        mb={36}
      />
      <CircularProgress color='brandBlue.500' size={100} isIndeterminate />
    </Center>
  );
}
