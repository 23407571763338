import { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { NextIntlProvider } from "next-intl";
import theme from "src/lib/theme/theme";
import Head from "next/head";
import { useApollo } from "lib/api/apolloClient";
import type { AppProps } from "next/dist/shared/lib/router/router";
import "react-quill/dist/quill.snow.css";
import "../styles/globals.css";
import { LocalesProvider } from "src/contexts/LocalesContext";
import { SessionProvider, useSession } from "next-auth/react";
import LoadingPage from "pages/LoadingPage";
import { UserProvider } from "src/contexts/useUser";
import { FALLBACK_LANGUAGE } from "lib/utils/constants";
import appVersion from "../version";

const AppWithHocs = ({
  Component,
  router: { locale },
  pageProps: {
    messages,
    initialApolloState,
    ...pageProps
  },
}: AppProps) => {
  const apolloClient = useApollo(initialApolloState);
  const { data, status } = useSession();

  useEffect(() => {
    // eslint-disable-next-line
    console.info("myHeart " + (appVersion.version || "dev") + " - " + (appVersion.commit || "no commit"))
  }, []);

  if (status === "loading" && !data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Head>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <title>myHeart Admin</title>
        <meta
          name="description"
          content="Plataforma myHeart Admin - Gestão de treinos"
        />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
      </Head>
      <ApolloProvider client={apolloClient}>
        <ChakraProvider theme={theme}>
          <NextIntlProvider messages={messages}>
            <LocalesProvider currentLocale={locale ?? FALLBACK_LANGUAGE}>
              <UserProvider>
                <Component {...pageProps} />
              </UserProvider>
            </LocalesProvider>
          </NextIntlProvider>
        </ChakraProvider>
      </ApolloProvider>
    </>
  );
};

const AppWithSession = ({ Component, pageProps: { session, ...pageProps }, ...rest }: AppProps) => (
  <SessionProvider session={session} refetchOnWindowFocus={true}>
    <AppWithHocs
      Component={Component}
      pageProps={pageProps}
      {...rest}
    />
  </SessionProvider>
);

export default AppWithSession;
