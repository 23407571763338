/* eslint-disable i18next/no-literal-string */
const Accordion = {
  parts: ["container", "button", "panel"],
  variants: {
    menuGroup: {
      container: {
        borderTopWidth: 0,
        _last: {
          borderBottomWidth: 0,
        },
        mx: "-10px",
      },
      button: {
        px: "10px",
        py: 0,
        fontSize: "1rem",
        _focus: {
          boxShadow: "none",
        },
        _hover: {
          bg: "none",
        },
        _expanded: {
          bgColor: "white",
        },
      },
      panel: {
        px: "25px",
        bgColor: "rgba(255,255,255, 0.7)",
      },
    },
    sectionEditor: {
      container: {},
      button: {
        color: "gray.400",
        letterSpacing: 0.5,
        fontWeight: "semibold",
        h: "80px",
        _focus: {
          boxShadow: "none",
        },
        _expanded: {
          bgColor: "white",
          color: "gray.600",
        },
      },
      panel: {
        bgColor: "white",
      },
    },
  },
};

export default Accordion;
