import { createContext, useCallback, useContext, useMemo } from "react";
import { FALLBACK_LANGUAGE } from "../lib/utils/constants";
import type { ILocalesListQuery, ILocale_Insert_Input } from "lib/api/sdk";
import { useLocalesListQuery } from "lib/api/sdk";

type LocalesContextProps = {
  getTranslations: <T extends TranslationCommonType>(translations: T[]) => T | null,
  locales: ILocalesListQuery["locale"],
  currentLocale: ILocale_Insert_Input
}

const LocalesContext = createContext({} as LocalesContextProps);

type Props = {
  currentLocale: string,
  children: React.ReactElement | React.ReactElement[]
}

export const LocalesProvider = ({ currentLocale, children }: Props) => {
  const LocalesReq = useLocalesListQuery();

  const getTranslations = useCallback(<T extends TranslationCommonType>(translations: T[]): T | null => {
    if (!translations || !currentLocale) return null;

    const preferedTranslation = translations?.find(t => t.locale?.code === currentLocale);
    if (preferedTranslation) return preferedTranslation;

    const fallbackTranslation = translations.find(t => t.locale?.code === FALLBACK_LANGUAGE);
    if (fallbackTranslation) return fallbackTranslation;

    return null;
  }, [currentLocale]);

  const value: LocalesContextProps = useMemo(() => ({
    getTranslations,
    locales: LocalesReq.data?.locale || [],
    currentLocale: LocalesReq.data?.locale.find(locale => locale.code === currentLocale)
  }), [currentLocale, getTranslations, LocalesReq.data]);

  return (
    <LocalesContext.Provider value={value}>
      {children}
    </LocalesContext.Provider>
  );
};

export const useLocales = () => useContext(LocalesContext);

export type TranslationCommonType = {
  id?: string | number;
  locale: ILocale_Insert_Input;
}

export type SportsTranslations = {
  title?: string;
  description?: string;
} & TranslationCommonType;

export type SubscriptionPlanTranslations = {
  description?: string;
} & TranslationCommonType;

export type ReviewTranslations = {
  text?: string;
} & TranslationCommonType;

export type WorkoutTypesTranslations = {
  name?: string;
} & TranslationCommonType;

export type CoachTranslations = {
  bio?: string;
} & TranslationCommonType;

export type CircuitsTranslations = {
  name?: string;
  description?: string;
} & TranslationCommonType;

export type ExercisesTranslations = {
  name?: string;
  description?: string;
} & TranslationCommonType;
