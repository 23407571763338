{/* eslint-disable i18next/no-literal-string */}
const FormLabelStyles = {
  baseStyle: {
    fontFamily: "body",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "brandBlue.500",
    marginBottom: 1,
  },
};

export default FormLabelStyles;
