import { signOut as signOutOG } from "next-auth/react";
import { useApollo } from "lib/api/apolloClient";
import type { ICoachBaseInfoFragment } from "lib/api/sdk";
import { CoachBaseInfoDocument } from "lib/api/sdk";
import { useSession } from "next-auth/react";
import type { ReactNode } from "react";
import { createContext, useCallback, useMemo, useState, useEffect, useContext } from "react";
import { getPaths } from "lib/utils/paths";
import LoadingPage from "pages/LoadingPage";
import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { LINKS, SignInRoles } from "lib/utils/constants";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";

const UserContext = createContext({} as UserContextValues);
const { Provider } = UserContext;

type UserData = {
  user_id?: number
  user_name?: string
  user_type: string;
  coach?: ICoachBaseInfoFragment["coach"];
}

type UserContextValues = {
  loggedIn: boolean
  loading: boolean
  updateUser: () => Promise<void>
  signOut: () => Promise<void>
  isAdmin: boolean
} & UserData

const JWT_REFRESH_INTERVAL = 1000 * 60 * 5;

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { data: session, update: updateSession } = useSession();
  const [user, setUser] = useState<UserData>();
  const [loading, setLoading] = useState(true);
  const apolloClient = useApollo({});
  const t = useTranslations();
  const { locale } = useRouter();

  const updateUser = useCallback(async () => {
    setLoading(true);
    const res = await apolloClient.query({
      query: CoachBaseInfoDocument,
      variables: { keycloak_id: session?.keycloak_id },
      fetchPolicy: "network-only"
    });
    const user = res.data?.user?.[0];

    if (user) setUser({
      user_id: user.id,
      user_name: user.name,
      user_type: session.mode,
      coach: user.coach,
    });
    setLoading(false);
  }, [session]);

  const signOut = useCallback(async (isAdmin: boolean) => {
    apolloClient.clearStore();
    if (isAdmin) {
      signOutOG({ callbackUrl: `${window.location.origin}/${locale}${getPaths.auth.loginAdmin()}` });
    } else {
      signOutOG({ callbackUrl: `${window.location.origin}/${locale}${getPaths.auth.login()}` });
    }
  }, [locale]);

  useEffect(() => {
    if (!session?.accessToken) {
      setUser(undefined);
      setLoading(false);
      return () => null;
    }

    // we have a session
    updateUser();

    const timer = setInterval(() => {
      updateSession();
    }, JWT_REFRESH_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  }, [session?.accessToken]);

  const value: UserContextValues = useMemo(() => ({
    ...user,
    updateUser,
    signOut: () => signOut(user?.user_type === SignInRoles.ADMIN),
    loading: loading,
    loggedIn: !!session?.accessToken && !!user?.user_id,
    isAdmin: user?.user_type === SignInRoles.ADMIN,
  }), [
    user,
    session,
    signOut,
    loading,
    updateUser,
  ]);

  return (
    <Provider value={value}>
      {value.loading
        ? <LoadingPage />
        : value.loggedIn && value.coach && !value.coach.is_verified
          ? (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100vh"
              gap={8}
              p={12}
              textAlign="center"
            >
              <Image
                // eslint-disable-next-line i18next/no-literal-string
                alt="myHeart logo"
                src={LINKS.images.bigLogo}
                h="240px"
                objectFit="contain"
                objectPosition="left"
                mb={8}
              />
              <Text>
                {t("contexts.useUser.coachNotVerifiedTitle")}
              </Text>
              <Text>
                {t("contexts.useUser.coachNotVerifiedMessage", { email: LINKS.MyHeartEmail })}
              </Text>
              <Button
                type="submit"
                colorScheme="brandBlue"
                px={12}
                size={"lg"}
                isLoading={loading}
                onClick={value.signOut}
              >
                {t("contexts.useUser.signOut")}
              </Button>
            </Flex>
          ) : children
      }
    </Provider>
  );
};

export const useUser = () => useContext(UserContext);
