import { useTranslations } from "next-intl";

export const SignInRoles = {
  // eslint-disable-next-line i18next/no-literal-string
  ADMIN: "admin",
  // eslint-disable-next-line i18next/no-literal-string
  COACH: "coach",
};

export const FALLBACK_LANGUAGE = "en";

export const COACH_MAX_SUBSCRIPTION_PLANS = 3;

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_MIN_DURATION_WEEKS = 4;

const useTranslationConstants = () => {
  const t = useTranslations("lib");

  const WEEK_DAY_DICTIONARY: any= {
    0: t("monday"),
    1: t("tuesday"),
    2: t("wednesday"),
    3: t("thursday"),
    4: t("friday"),
    5: t("saturday"),
    6: t("sunday"),
  };

  const SUBSCRIPTION_PLAN_NAMES: { level: number, name: string}[] = [
    {
      "level": 1,
      "name": t("base"),
    },
    {
      "level": 2,
      "name": t("performance"),
    },
    {
      "level": 3,
      "name": t("highPerformance"),
    },
  ];

  const getSubscriptionPlanName = (level: number) => {
    if (!level) return "";
    return SUBSCRIPTION_PLAN_NAMES.find(plan => plan.level === level).name;
  };

  const SUBSCRIPTION_STATUS: any = {
    active: { label: t("active"), color: "green" },
    canceled: { label: t("canceled"), color: "red" },
    incomplete: { label: t("incomplete"), color: "gray" },
    incomplete_expired: { label: t("expiredIncomplete"), color: "gray" },
    past_due: { label: t("expired"), color: "gray" },
    trialing: { label: t("inTest"), color: "blue" },
    unpaid: { label: t("failedPayment"), color: "orange" },
  };

  const SECTION_TYPES: any = {
    warm_up: { label: t("warmUp"), color: "green" },
    series_warm_up: { label: t("warmUp"), color: "green" },
    cool_down: { label: t("recovery"), color: "blue" },
    series_cool_down: { label: t("recovery"), color: "blue" },
    main: { label: t("mainTask"), color: "brandOrange" },
    extra: { label: t("extra"), color: "blue" },
    series_acceleration: { label: t("accelaration"), color: "yellow" },
    acceleration: { label: t("accelaration"), color: "yellow" },
    series_main: { label: t("strong"), color: "red" },
    series_recovery: { label: t("slow"), color: "purple" },
    recovery: { label: t("slow"), color: "purple" },
    default: { label: t("notDefined"), color: "gray" },
  };

  const WORKOUT_TYPE: any = {
    bidaily: { label: t("biDaily"), color: "blue" },
    cumulate: { label: t("accumulated"), color: "green" },
    default: { label: t("notDefined"), color: "gray" },
  };

  const EXERCISE_TYPE_ENUMS = [
    {
      url_value: "weight_training",
      value: "WEIGHT",
      label: t("weight")
    },
    {
      url_value: "flexibility",
      value: "STRETCH",
      label: t("stretch")
    },
  ];

  const GENDER_ENUMS = [
    { value: "FEMALE", label: t("female") },
    { value: "MALE", label: t("male") },
  ];

  const ATHLETE_PROFILE_TYPE_ENUMS = [
    {
      value: "BASE",
      label: t("athlete_base"),
      color: "gray"
    },
    {
      value: "MEDIUM",
      label: t("athlete_medium"),
      color: "blue"
    },
    {
      value: "ADVANCED",
      label: t("athlete_advanced"),
      color: "brandBlue"
    },
  ];

  const EXERCISE_TARGET_ENUMS = [
    {
      value: "LEGS",
      label: t("legs"),
    },
    {
      value: "ARMS",
      label: t("arms"),
    },
    {
      value: "TORSO",
      label: t("torso"),
    },
    {
      value: "FULL_BODY",
      label: t("fullBody"),
    },
  ];

  return {
    WEEK_DAY_DICTIONARY,
    SUBSCRIPTION_PLAN_NAMES,
    getSubscriptionPlanName,
    SUBSCRIPTION_STATUS,
    SECTION_TYPES,
    WORKOUT_TYPE,
    EXERCISE_TYPE_ENUMS,
    GENDER_ENUMS,
    ATHLETE_PROFILE_TYPE_ENUMS,
    EXERCISE_TARGET_ENUMS,
  };
};

export const WORKOUT_SECTION_TEMPLATE_DEFAULT_VALUES: any = {
  "1": {
    speed: 0,
    average_heart_rate: 50,
    max_heart_rate: 5,
    duration: 60,
    type: "main",
  },
  "2": {
    speed: 0,
    average_heart_rate: 50,
    max_heart_rate: 5,
    duration: 60,
    type: "main",
  },
  "3": {
    speed: 0,
    average_heart_rate: 50,
    max_heart_rate: 2,
    duration: 40,
    type: "main",
  },
  "5": {
    speed: 0,
    average_heart_rate: 50,
    max_heart_rate: 9,
    duration: 60,
    type: "main",
  },
  "6": {
    speed: 0,
    average_heart_rate: 50,
    max_heart_rate: 9,
    duration: 90,
    type: "main",
  },
};

export const WORKOUT_SECTION_TEMPLATE_SERIES_DEFAULT_VALUES: any = {
  "1": {
    speed: 0,
    average_heart_rate: 50,
    max_heart_rate: 5,
    duration: "3:00",
  },
  "2": {
    speed: 0,
    average_heart_rate: 50,
    max_heart_rate: 5,
    duration: "1:00",
  },
  "3": {
    speed: 0,
    average_heart_rate: 50,
    max_heart_rate: 2,
    duration: "1:00",
  },
  "4": {
    speed: 0,
    average_heart_rate: 50,
    max_heart_rate: 5,
    duration: "3:00",
  },
  "5": {
    speed: 0,
    average_heart_rate: 50,
    max_heart_rate: 9,
    duration: "1:00",
  },
};

// TODO: this could also go on the BD

export const WORKOUT_DEFAULT_VALUES: any = {
  1: { workout_altimetry_id: 5, workout_terrain_id: 2 },
  2: { workout_altimetry_id: 5, workout_terrain_id: 2 },
  3: { workout_altimetry_id: 5, workout_terrain_id: 6 },
  4: { workout_altimetry_id: 1, workout_terrain_id: 3 },
  5: { workout_altimetry_id: 1, workout_terrain_id: 1 },
  6: { workout_altimetry_id: 5, workout_terrain_id: 2 },
  7: { workout_altimetry_id: 1, workout_terrain_id: 3 },
  8: { workout_altimetry_id: 1, workout_terrain_id: 3 },
};

export const PA_ZONE_ID = 4;
export const LD_ZONE_ID = 5;

export const LINKS = {
  "images": {

    // myHeart
    // "nameLogo": "/images/logos/MyHeart_Name.svg",
    "bigLogo": "/images/logos/MyHeart_Logo.svg",
    "miniLogo": "/images/logos/MyHeart_Logo_Mini.svg",

    // Strava
    "poweredByStrava": "/images/strava/api_logo_pwrdBy_strava_horiz_gray.png"
  },

  "stripeCoupons": "https://dashboard.stripe.com/coupons",

  // Emails
  "MyHeartEmail": "info@myheart.sport",
  "MyHeartEmailMailTo": "mailto:coach@myheart.sport",

  // TOC
  "termsAndConditions": "https://drive.google.com/file/d/19tvwfVhTopLpLbQWqL3poPLSBYKnwPOa/preview",
};

export default useTranslationConstants;

export type CoachAvailableLanguages = keyof typeof COACH_AVAILABLE_LANGUAGES;

export const COACH_AVAILABLE_LANGUAGES = ({
  "pt": { label: "Português", flagCode: "PT" },
  "en": { label: "English", flagCode: "GB" },
  "es": { label: "Español", flagCode: "ES" },
  "fr": { label: "Français", flagCode: "FR" },
  "it": { label: "Italiano", flagCode: "IT" },
  "de": { label: "German", flagCode: "DE" },
  "sv": { label: "Swedish", flagCode: "SE" },
  "nl": { label: "Nederlandse", flagCode: "NL" },
  "ro": { label: "Română", flagCode: "RO" },
  "tr": { label: "Türk", flagCode: "TR" },
  "pl": { label: "Polski", flagCode: "PL" },
  "da": { label: "Dansk", flagCode: "DK" },
  "sw": { label: "Kiswahili", flagCode: "KE" },
  "sq": { label: "shqiptar", flagCode: "AL" },
  "gr": { label: "ελληνική", flagCode: "GR" },
  "ms": { label: "Melayu", flagCode: "MY" },
  "fa": { label: "پارسی", flagCode: "IR" },
  "ar": { label: "العربية", flagCode: "SA" },
  "bn": { label: "বাংলা", flagCode: "ID" },
  "ch": { label: "中文", flagCode: "CN" },
  "hi": { label: "हिंदुस्तानी", flagCode: "IN" },
  "ko": { label: "한국어", flagCode: "KR" },
  "ru": { label: "русский", flagCode: "RU" },
  "sr": { label: "Српско-хрватски", flagCode: "RS" },
  "ta": { label: "தமிழ்", flagCode: "LK" },
}) as const;

export const COUNTRIES = [
  {
    code: "AF",
    name: "Afghanistan"
  },
  {
    code: "AX",
    name: "Åland Islands"
  },
  {
    code: "AL",
    name: "Albania"
  },
  {
    code: "DZ",
    name: "Algeria"
  },
  {
    code: "AS",
    name: "American Samoa"
  },
  {
    code: "AD",
    name: "Andorra"
  },
  {
    code: "AO",
    name: "Angola"
  },
  {
    code: "AI",
    name: "Anguilla"
  },
  {
    code: "AQ",
    name: "Antarctica"
  },
  {
    code: "AG",
    name: "Antigua and Barbuda"
  },
  {
    code: "AR",
    name: "Argentina"
  },
  {
    code: "AM",
    name: "Armenia"
  },
  {
    code: "AW",
    name: "Aruba"
  },
  {
    code: "AU",
    name: "Australia"
  },
  {
    code: "AT",
    name: "Austria"
  },
  {
    code: "AZ",
    name: "Azerbaijan"
  },
  {
    code: "BS",
    name: "Bahamas"
  },
  {
    code: "BH",
    name: "Bahrain"
  },
  {
    code: "BD",
    name: "Bangladesh"
  },
  {
    code: "BB",
    name: "Barbados"
  },
  {
    code: "BY",
    name: "Belarus"
  },
  {
    code: "BE",
    name: "Belgium"
  },
  {
    code: "BZ",
    name: "Belize"
  },
  {
    code: "BJ",
    name: "Benin"
  },
  {
    code: "BM",
    name: "Bermuda"
  },
  {
    code: "BT",
    name: "Bhutan"
  },
  {
    code: "BO",
    name: "Bolivia, Plurinational State of"
  },
  {
    code: "BQ",
    name: "Bonaire, Sint Eustatius and Saba"
  },
  {
    code: "BA",
    name: "Bosnia and Herzegovina"
  },
  {
    code: "BW",
    name: "Botswana"
  },
  {
    code: "BV",
    name: "Bouvet Island"
  },
  {
    code: "BR",
    name: "Brazil"
  },
  {
    code: "IO",
    name: "British Indian Ocean Territory"
  },
  {
    code: "BN",
    name: "Brunei Darussalam"
  },
  {
    code: "BG",
    name: "Bulgaria"
  },
  {
    code: "BF",
    name: "Burkina Faso"
  },
  {
    code: "BI",
    name: "Burundi"
  },
  {
    code: "CV",
    name: "Cabo Verde"
  },
  {
    code: "KH",
    name: "Cambodia"
  },
  {
    code: "CM",
    name: "Cameroon"
  },
  {
    code: "CA",
    name: "Canada"
  },
  {
    code: "KY",
    name: "Cayman Islands"
  },
  {
    code: "CF",
    name: "Central African Republic"
  },
  {
    code: "TD",
    name: "Chad"
  },
  {
    code: "CL",
    name: "Chile"
  },
  {
    code: "CN",
    name: "China"
  },
  {
    code: "CX",
    name: "Christmas Island"
  },
  {
    code: "CC",
    name: "Cocos (Keeling) Islands"
  },
  {
    code: "CO",
    name: "Colombia"
  },
  {
    code: "KM",
    name: "Comoros"
  },
  {
    code: "CG",
    name: "Congo"
  },
  {
    code: "CD",
    name: "Congo, Democratic Republic of the"
  },
  {
    code: "CK",
    name: "Cook Islands"
  },
  {
    code: "CR",
    name: "Costa Rica"
  },
  {
    code: "HR",
    name: "Croatia"
  },
  {
    code: "CU",
    name: "Cuba"
  },
  {
    code: "CW",
    name: "Curaçao"
  },
  {
    code: "CY",
    name: "Cyprus"
  },
  {
    code: "CZ",
    name: "Czechia"
  },
  {
    code: "CI",
    name: "Côte d'Ivoire"
  },
  {
    code: "DK",
    name: "Denmark"
  },
  {
    code: "DJ",
    name: "Djibouti"
  },
  {
    code: "DM",
    name: "Dominica"
  },
  {
    code: "DO",
    name: "Dominican Republic"
  },
  {
    code: "EC",
    name: "Ecuador"
  },
  {
    code: "EG",
    name: "Egypt"
  },
  {
    code: "SV",
    name: "El Salvador"
  },
  {
    code: "GQ",
    name: "Equatorial Guinea"
  },
  {
    code: "ER",
    name: "Eritrea"
  },
  {
    code: "EE",
    name: "Estonia"
  },
  {
    code: "SZ",
    name: "Eswatini"
  },
  {
    code: "ET",
    name: "Ethiopia"
  },
  {
    code: "FK",
    name: "Falkland Islands (Malvinas)"
  },
  {
    code: "FO",
    name: "Faroe Islands"
  },
  {
    code: "FJ",
    name: "Fiji"
  },
  {
    code: "FI",
    name: "Finland"
  },
  {
    code: "FR",
    name: "France"
  },
  {
    code: "GF",
    name: "French Guiana"
  },
  {
    code: "PF",
    name: "French Polynesia"
  },
  {
    code: "TF",
    name: "French Southern Territories"
  },
  {
    code: "GA",
    name: "Gabon"
  },
  {
    code: "GM",
    name: "Gambia"
  },
  {
    code: "GE",
    name: "Georgia"
  },
  {
    code: "DE",
    name: "Germany"
  },
  {
    code: "GH",
    name: "Ghana"
  },
  {
    code: "GI",
    name: "Gibraltar"
  },
  {
    code: "GR",
    name: "Greece"
  },
  {
    code: "GL",
    name: "Greenland"
  },
  {
    code: "GD",
    name: "Grenada"
  },
  {
    code: "GP",
    name: "Guadeloupe"
  },
  {
    code: "GU",
    name: "Guam"
  },
  {
    code: "GT",
    name: "Guatemala"
  },
  {
    code: "GG",
    name: "Guernsey"
  },
  {
    code: "GN",
    name: "Guinea"
  },
  {
    code: "GW",
    name: "Guinea-Bissau"
  },
  {
    code: "GY",
    name: "Guyana"
  },
  {
    code: "HT",
    name: "Haiti"
  },
  {
    code: "HM",
    name: "Heard Island and McDonald Islands"
  },
  {
    code: "VA",
    name: "Holy See"
  },
  {
    code: "HN",
    name: "Honduras"
  },
  {
    code: "HK",
    name: "Hong Kong"
  },
  {
    code: "HU",
    name: "Hungary"
  },
  {
    code: "IS",
    name: "Iceland"
  },
  {
    code: "IN",
    name: "India"
  },
  {
    code: "ID",
    name: "Indonesia"
  },
  {
    code: "IR",
    name: "Iran, Islamic Republic of"
  },
  {
    code: "IQ",
    name: "Iraq"
  },
  {
    code: "IE",
    name: "Ireland"
  },
  {
    code: "IM",
    name: "Isle of Man"
  },
  {
    code: "IL",
    name: "Israel"
  },
  {
    code: "IT",
    name: "Italy"
  },
  {
    code: "JM",
    name: "Jamaica"
  },
  {
    code: "JP",
    name: "Japan"
  },
  {
    code: "JE",
    name: "Jersey"
  },
  {
    code: "JO",
    name: "Jordan"
  },
  {
    code: "KZ",
    name: "Kazakhstan"
  },
  {
    code: "KE",
    name: "Kenya"
  },
  {
    code: "KI",
    name: "Kiribati"
  },
  {
    code: "KP",
    name: "Korea, Democratic People's Republic of"
  },
  {
    code: "KR",
    name: "Korea, Republic of"
  },
  {
    code: "KW",
    name: "Kuwait"
  },
  {
    code: "KG",
    name: "Kyrgyzstan"
  },
  {
    code: "LA",
    name: "Lao People's Democratic Republic"
  },
  {
    code: "LV",
    name: "Latvia"
  },
  {
    code: "LB",
    name: "Lebanon"
  },
  {
    code: "LS",
    name: "Lesotho"
  },
  {
    code: "LR",
    name: "Liberia"
  },
  {
    code: "LY",
    name: "Libya"
  },
  {
    code: "LI",
    name: "Liechtenstein"
  },
  {
    code: "LT",
    name: "Lithuania"
  },
  {
    code: "LU",
    name: "Luxembourg"
  },
  {
    code: "MO",
    name: "Macao"
  },
  {
    code: "MG",
    name: "Madagascar"
  },
  {
    code: "MW",
    name: "Malawi"
  },
  {
    code: "MY",
    name: "Malaysia"
  },
  {
    code: "MV",
    name: "Maldives"
  },
  {
    code: "ML",
    name: "Mali"
  },
  {
    code: "MT",
    name: "Malta"
  },
  {
    code: "MH",
    name: "Marshall Islands"
  },
  {
    code: "MQ",
    name: "Martinique"
  },
  {
    code: "MR",
    name: "Mauritania"
  },
  {
    code: "MU",
    name: "Mauritius"
  },
  {
    code: "YT",
    name: "Mayotte"
  },
  {
    code: "MX",
    name: "Mexico"
  },
  {
    code: "FM",
    name: "Micronesia, Federated States of"
  },
  {
    code: "MD",
    name: "Moldova, Republic of"
  },
  {
    code: "MC",
    name: "Monaco"
  },
  {
    code: "MN",
    name: "Mongolia"
  },
  {
    code: "ME",
    name: "Montenegro"
  },
  {
    code: "MS",
    name: "Montserrat"
  },
  {
    code: "MA",
    name: "Morocco"
  },
  {
    code: "MZ",
    name: "Mozambique"
  },
  {
    code: "MM",
    name: "Myanmar"
  },
  {
    code: "NA",
    name: "Namibia"
  },
  {
    code: "NR",
    name: "Nauru"
  },
  {
    code: "NP",
    name: "Nepal"
  },
  {
    code: "NL",
    name: "Netherlands"
  },
  {
    code: "NC",
    name: "New Caledonia"
  },
  {
    code: "NZ",
    name: "New Zealand"
  },
  {
    code: "NI",
    name: "Nicaragua"
  },
  {
    code: "NE",
    name: "Niger"
  },
  {
    code: "NG",
    name: "Nigeria"
  },
  {
    code: "NU",
    name: "Niue"
  },
  {
    code: "NF",
    name: "Norfolk Island"
  },
  {
    code: "MK",
    name: "North Macedonia"
  },
  {
    code: "MP",
    name: "Northern Mariana Islands"
  },
  {
    code: "NO",
    name: "Norway"
  },
  {
    code: "OM",
    name: "Oman"
  },
  {
    code: "PK",
    name: "Pakistan"
  },
  {
    code: "PW",
    name: "Palau"
  },
  {
    code: "PS",
    name: "Palestine, State of"
  },
  {
    code: "PA",
    name: "Panama"
  },
  {
    code: "PG",
    name: "Papua New Guinea"
  },
  {
    code: "PY",
    name: "Paraguay"
  },
  {
    code: "PE",
    name: "Peru"
  },
  {
    code: "PH",
    name: "Philippines"
  },
  {
    code: "PN",
    name: "Pitcairn"
  },
  {
    code: "PL",
    name: "Poland"
  },
  {
    code: "PT",
    name: "Portugal"
  },
  {
    code: "PR",
    name: "Puerto Rico"
  },
  {
    code: "QA",
    name: "Qatar"
  },
  {
    code: "RO",
    name: "Romania"
  },
  {
    code: "RU",
    name: "Russian Federation"
  },
  {
    code: "RW",
    name: "Rwanda"
  },
  {
    code: "RE",
    name: "Réunion"
  },
  {
    code: "BL",
    name: "Saint Barthélemy"
  },
  {
    code: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    code: "KN",
    name: "Saint Kitts and Nevis"
  },
  {
    code: "LC",
    name: "Saint Lucia"
  },
  {
    code: "MF",
    name: "Saint Martin (French part)"
  },
  {
    code: "PM",
    name: "Saint Pierre and Miquelon"
  },
  {
    code: "VC",
    name: "Saint Vincent and the Grenadines"
  },
  {
    code: "WS",
    name: "Samoa"
  },
  {
    code: "SM",
    name: "San Marino"
  },
  {
    code: "ST",
    name: "Sao Tome and Principe"
  },
  {
    code: "SA",
    name: "Saudi Arabia"
  },
  {
    code: "SN",
    name: "Senegal"
  },
  {
    code: "RS",
    name: "Serbia"
  },
  {
    code: "SC",
    name: "Seychelles"
  },
  {
    code: "SL",
    name: "Sierra Leone"
  },
  {
    code: "SG",
    name: "Singapore"
  },
  {
    code: "SX",
    name: "Sint Maarten (Dutch part)"
  },
  {
    code: "SK",
    name: "Slovakia"
  },
  {
    code: "SI",
    name: "Slovenia"
  },
  {
    code: "SB",
    name: "Solomon Islands"
  },
  {
    code: "SO",
    name: "Somalia"
  },
  {
    code: "ZA",
    name: "South Africa"
  },
  {
    code: "GS",
    name: "South Georgia and the South Sandwich Islands"
  },
  {
    code: "SS",
    name: "South Sudan"
  },
  {
    code: "ES",
    name: "Spain"
  },
  {
    code: "LK",
    name: "Sri Lanka"
  },
  {
    code: "SD",
    name: "Sudan"
  },
  {
    code: "SR",
    name: "Suriname"
  },
  {
    code: "SJ",
    name: "Svalbard and Jan Mayen"
  },
  {
    code: "SE",
    name: "Sweden"
  },
  {
    code: "CH",
    name: "Switzerland"
  },
  {
    code: "SY",
    name: "Syrian Arab Republic"
  },
  {
    code: "TW",
    name: "Taiwan, Province of China"
  },
  {
    code: "TJ",
    name: "Tajikistan"
  },
  {
    code: "TZ",
    name: "Tanzania, United Republic of"
  },
  {
    code: "TH",
    name: "Thailand"
  },
  {
    code: "TL",
    name: "Timor-Leste"
  },
  {
    code: "TG",
    name: "Togo"
  },
  {
    code: "TK",
    name: "Tokelau"
  },
  {
    code: "TO",
    name: "Tonga"
  },
  {
    code: "TT",
    name: "Trinidad and Tobago"
  },
  {
    code: "TN",
    name: "Tunisia"
  },
  {
    code: "TR",
    name: "Turkey"
  },
  {
    code: "TM",
    name: "Turkmenistan"
  },
  {
    code: "TC",
    name: "Turks and Caicos Islands"
  },
  {
    code: "TV",
    name: "Tuvalu"
  },
  {
    code: "UG",
    name: "Uganda"
  },
  {
    code: "UA",
    name: "Ukraine"
  },
  {
    code: "AE",
    name: "United Arab Emirates"
  },
  {
    code: "GB",
    name: "United Kingdom"
  },
  {
    code: "UM",
    name: "United States Minor Outlying Islands"
  },
  {
    code: "US",
    name: "United States"
  },
  {
    code: "UY",
    name: "Uruguay"
  },
  {
    code: "UZ",
    name: "Uzbekistan"
  },
  {
    code: "VU",
    name: "Vanuatu"
  },
  {
    code: "VE",
    name: "Venezuela, Bolivarian Republic of"
  },
  {
    code: "VN",
    name: "Viet Nam"
  },
  {
    code: "VG",
    name: "Virgin Islands, British"
  },
  {
    code: "VI",
    name: "Virgin Islands, U.S."
  },
  {
    code: "WF",
    name: "Wallis and Futuna"
  },
  {
    code: "EH",
    name: "Western Sahara"
  },
  {
    code: "YE",
    name: "Yemen"
  },
  {
    code: "ZM",
    name: "Zambia"
  },
  {
    code: "ZW",
    name: "Zimbabwe"
  }
];
